import React, { useState, useEffect } from 'react';
import sanityClient from '@sanity/client';
import Layout from '../components/Layout';
import Content from '../components/Content';
import Loader from '../components/helpers/Loader';

const preview = () => {
  const [previewData, setPreviewData] = useState(null);
  const [pageLoad, setPageLoad] = useState(null);

  useEffect(() => {
    const client = sanityClient({
      projectId: process.env.GATSBY_SANITY_ID,
      dataset: process.env.GATSBY_SANITY_DATASET,
      token: process.env.GATSBY_SANITY_TOKEN,
    });

    const idReturn = (url) => {
      const initSplit = url.split('?docid=')[1];
      const splitIDs = initSplit.split('&listing=')[0];
      const listing = initSplit.split('&listing=')[1];
      return [splitIDs, listing];
    };

    if (global.window.location.search) {
      const query = `*[_id in path("${
        idReturn(global.window.location.search)[0]
      }")]`;

      client.fetch(query).then((data) => {
        setPreviewData(data[0]);
        setPageLoad(false);
      });
    }
  }, []);
  return (
    <Layout title="Preview" pageImage={null}>
      {pageLoad && !previewData && <Loader bg />}
      {!pageLoad && previewData && (
        <>
          {previewData._type === 'page' && (
            <>
              {previewData.pageContent && (
                <Content
                  mainImage={previewData.mainImage}
                  contentArray={previewData.pageContent}
                />
              )}
            </>
          )}
        </>
      )}
    </Layout>
  );
};

export default preview;
